<template>
  <div class="home">
    <section id='home-banner' class="align-self-center">
      <div class="container">
        <div class="row" >
          <div class="col-12 text-center">
            <h1 class="banner-title align-self-center">
              Geegz
            </h1>
            <div class="row mt-3">
              <div class="col-md-4 text-center">
                <a href="#metiers-section" @click="changeMetierTab('WEB')" style="text-decoration: none;">
                  <h2 class="banner-slogan slogan-margin font-alt color-text-on-black">
                    DEVELOPPEMENT WEB
                  </h2>
                </a>
              </div>
              <div class="col-md-5 text-center">
                <a href="#metiers-section" @click="changeMetierTab('DATA')" style="text-decoration: none;">
                  <h2 class="banner-slogan slogan-margin font-alt color-text-on-black">
                    DATA SCIENCE
                  </h2>
                </a>
              </div>
              <div class="col-md-3 text-center">
                <a href="#metiers-section" @click="changeMetierTab('CYBER')" style="text-decoration: none;">
                  <h2 class="banner-slogan slogan-margin font-alt color-text-on-black">
                    CYBERSECURITE
                  </h2>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="#metiers-section" class="scroll-down d-none d-md-block" style="color: white;">
        <b-icon-chevron-compact-down class="scroll-down-icon"></b-icon-chevron-compact-down>
      </a>

      <!--<ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>-->
    </section>


    <section id="metiers-section">
      <div class="container text-center">
        <div class="row section-title-margin">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title">
              Métiers
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-6 col-lg-4 metiers-alt-tabs">
            <a href="javascript:void(0);" v-bind:class="{ 'metier-tab-active': activeMetierTab == 'WEB' }" @click="changeMetierTab('WEB')">
              <div class="alt-tabs-icon">
                <b-icon-display></b-icon-display>
              </div>
              Développement web
            </a>
          </div>
          <div class="col-6 col-lg-4 metiers-alt-tabs">
            <a href="javascript:void(0);" v-bind:class="{ 'metier-tab-active': activeMetierTab == 'DATA' }" @click="changeMetierTab('DATA')">
              <div class="alt-tabs-icon">
                <b-icon-gear></b-icon-gear>
              </div>
              Data science
            </a>
          </div>
          <div class="col-12 mt-2 col-lg-4 mt-lg-0 metiers-alt-tabs">
            <a href="javascript:void(0);" v-bind:class="{ 'metier-tab-active': activeMetierTab == 'CYBER' }" @click="changeMetierTab('CYBER')">
              <div class="alt-tabs-icon">
                <b-icon-shield-lock></b-icon-shield-lock>
              </div>
              Cybersécurité
            </a>
          </div>
        </div>

        <div class="tab-content metiers-tabs-cont">
          <div id="developpement-web-tab" v-if="activeMetierTab == 'WEB'">
            <div class="row">
              <div class="col-lg-4 section-slogan">
                Votre application web disponible en une semaine
              </div>
              <div class="col-lg-4 mt-3 mt-md-0 section-explanation">
                Notre objectif : que vous puissiez tester votre produit <u>en moins d'une semaine</u>
                après sa commande.
                Pour cela, nous allions des compétences de conception, de développement (web, mobile,
                applicatif) et de déploiement pour vous accompagner tout au long de
                la construction de votre produit.
              </div>
              <div class="col-lg-4 section-explanation">
                Notre équipe vous permet de concevoir rapidement des plateformes et applications
                complexes à destination de vos équipes internes ou de vos clients.
                Nous vous permettons de récolter et d'héberger
                des données personnelles et/ou de santé.
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6 offset-3 text-center">
                <b-button to='/developpement-web' block variant="outline-secondary" size="sm">
                  En savoir plus
                </b-button>
              </div>
            </div>
          </div>
          <div id="data-science-tab" v-if="activeMetierTab == 'DATA'">
            <div class="row">
              <div class="col-md-4 section-slogan">
                Des données valorisées et utilisables directement par vos équipes
              </div>
              <div class="col-lg-4 mt-3 mt-md-0 section-explanation">
                Notre équipe se doit d'être la plus talentueuse pour vous.
                Nous donnons du sens à vos données et construisons des solutions
                technologiques vous permettant d'en tirer un maximum de valeur.
              </div>
              <div class="col-lg-4 section-explanation">
                Notre équipe met à votre disposition des compétences d'intelligence marketing et d'analyse de marché.
                Nous vous proposons également des solutions technologiques clés en main comme nos algorithmes
                de webscrapping vous permettant de construire et de qualifier une liste de prospects.
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6 offset-3 text-center">
                <b-button to='/data-science' block variant="outline-secondary" size="sm">
                  En savoir plus
                </b-button>
              </div>
            </div>
          </div>
          <div id="cybersecurite-tab" v-if="activeMetierTab == 'CYBER'">
            <div class="row">
              <div class="col-md-4 section-slogan">
                Des sytèmes et une infrastructure sécurisés pour une activité pérenne
              </div>
              <div class="col-md-4 mt-3 mt-md-0 section-explanation">
                Les attaques cyber ont augmenté de plus de 25% en une année : ransomware, malware, rootkits, spyware, ...
                profitent des failles de vos systèmes et mettent
                en péril votre activité. A destination des TPE/PME et indépendants, nous auditons et configurons
                l'ensemble de votre système (OS, navigateurs, logiciels, réseaux) pour vous garantir une sécurité et
                confidentialité maximales.
              </div>
              <div class="col-md-4 section-explanation">
                Nous dressons des cyberportraits par récupération algorithmique d'informations, menons des
                cyberinvestigations et testons les failles de votre activité face à des attaques de social engineering*.
                Nous testons vos applications web, vos réseaux ainsi que la résistance de vos mots de passe*.<br>
                <em><small>* dans un cadre autorisé réglementairement</small></em>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6 offset-3 text-center">
                <b-button to='/cyber-securite' block variant="outline-secondary" size="sm">
                  En savoir plus
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="realisations-section">
      <div class="container text-center">
        <div class="row">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title section-title-margin">
              Dernières réalisations
            </h3>
          </div>
        </div>

        <div class="realisations-alt-tabs">
          <a class="mt-2" block href="javascript:void(0);" v-b-toggle.corona-collapse>
            Plateforme d'assistance liée à l'épidémie de Covid19
          </a>
        </div>
        <div>
          <b-collapse class=" mt-4 section-explanation text-explanation-on-black" id="corona-collapse" accordion="realisations-accordion" role="tabpanel">
            <p>
              Suite aux conséquences économiques et financières de l'épidémie de Covid19, notre
              client veut mettre à disposition de ses clients
              une plateforme d'informations et d'assistance concernant les dispostifs sociaux,
              financiers, fiscaux et juridiques
              sur lesquels peuvent s'appuyer les entreprises et indépedants.<br>
              <em><small>En partenariat avec le cabinet Mayer Prezioso sur le contenu juridique</small></em>
            </p>
            <em>Plateforme à réaliser en urgence</em>
            <p>
              <strong>Temps de mise à disposition du client : 2 jours</strong>
            </p>
            <p>
              <strong>Temps de mise sur le marché : 5 jours</strong>
            </p>
          </b-collapse>
        </div>

        <div class="realisations-alt-tabs">
          <a block href="javascript:void(0);" v-b-toggle.prospect-collapse>
            Analyse sectorielle et outils de prospection
          </a>
        </div>
        <div>
          <b-collapse class=" mt-4 section-explanation text-explanation-on-black" id="prospect-collapse" accordion="realisations-accordion" role="tabpanel">
            <p> Notre client souhaite investir le segment des TNS, indépendants et professions libérales.
              Nous réalisons pour lui une étude d'opportunité et construisons un fichier de prospection qualifié et
              RGPD-compatible de l'ensemble des indépedants
              en Alsace
            </p>
            <p>
              <strong>Temps de construction du fichier de prospection : 3 jours</strong>
            </p>
            <p>
              <strong>Informations mises à disposition : Siret, adresse, nom, prénom, numéro de téléphone; chiffre d'affaires, etc.</strong>
            </p>
            <b-button href="./offre_geegz_fichiers_prospection.pdf" download class="p-2 text-center" variant="outline-light" size="sm">
              Téléchargez notre offre de construction de base de prospects
            </b-button>
          </b-collapse>
        </div>

        <div class="realisations-alt-tabs">
          <a class="mt-2" block href="javascript:void(0);" v-b-toggle.courtage-collapse>
            Construction d'une application web de courtage en assurance
          </a>
        </div>
        <div>
          <b-collapse class=" mt-4 section-explanation text-explanation-on-black" id="courtage-collapse" accordion="realisations-accordion" role="tabpanel">
            <p>
              Notre client lance une activité de courtage en assurance de personnes à
              destination des indépendants. Il nous mandate
              pour concevoir et construire une plateforme permettant la tarification en ligne, la simulation
              des remboursements et la souscription de produits en ligne.<br>
            </p>
            <p>
              <strong>Temps de première mise à disposition client : 1 semaine</strong>
            </p>
            <p>
              <strong>Temps de mise sur le marché : 3 semaines</strong>
            </p>
          </b-collapse>
        </div>

        <div class="realisations-alt-tabs">
          <a class="mt-2" block href="javascript:void(0);" v-b-toggle.cilver-collapse>
            Marketplace de services à la personne
          </a>
        </div>
        <div>
          <b-collapse class=" mt-4 section-explanation text-explanation-on-black" id="cilver-collapse" accordion="realisations-accordion" role="tabpanel">
            <p>
              Notre client nous confie la création d'une marketplace de services aux personnes âgées.
              Nous mettons en place un système de recherche entre utilisateurs et professionnels du service à
              la personne, de prise de rendez-vous et de paiement 100% en ligne.
            </p>
            <p>
              <strong>Temps de première mise à disposition client : 2 semaines</strong>
            </p>
            <p>
              <strong>Temps de mise sur le marché : 1 mois et demi</strong>
            </p>
          </b-collapse>
        </div>

      </div>
    </section>




    <section id='raisons-choisir-global' style="padding-bottom: 50px;">
      <div class="container pt-2">
        <div class="row">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title section-title-margin">
              Pourquoi nous choisir ?
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="row basic-alt-tabs text-explanation-on-white">
              <div class="col-12">
                <div class="alt-tabs-icon">
                  <b-icon-award></b-icon-award>
                </div>
                Expertise
              </div>
            </div>
            <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
              <div class="col-12">
                Nous allions les métiers de développement d'applications, de data science et
                de cybersécurité. Dans ces trois métiers, nous visons l'excellence et
                apportons à nos clients une vision exigeante et des solutions éprouvées utilisant les toutes
                dernières technologies.
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mt-4 mt-lg-0">
            <div class="row basic-alt-tabs text-explanation-on-white">
              <div class="col-12">
                <div class="alt-tabs-icon">
                  <b-icon-lightning></b-icon-lightning>
                </div>
                Réactivité
              </div>
            </div>
            <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
              <div class="col-12">
                Notre équipe se doit d'être la plus réactive possible.
                Nous faisons preuve d'agilité pour itérer et construire les solutions
                de nos clients en un temps record. Notre promesse : Permettre à nos clients de tester leur produit
                en moins d'une semaine.
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mt-4">
            <div class="row basic-alt-tabs text-explanation-on-white">
              <div class="col-12">
                <div class="alt-tabs-icon">
                  <b-icon-command></b-icon-command>
                </div>
                Créativité
              </div>
            </div>
            <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
              <div class="col-12">
                La différenciation pertinente est créatrice de valeur. Nous sommes des esprits libres, force
                de proposition, aguerris à nos métiers. La créativité que nous véhiculons porte sur la nature
                de notre engagement, notre manière d'aborder des problématiques et de répondre aux besoins de nos clients.
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mt-4">
            <div class="row basic-alt-tabs text-explanation-on-white">
              <div class="col-12">
                <div class="alt-tabs-icon">
                  <b-icon-heart></b-icon-heart>
                </div>
                Générosité
              </div>
            </div>
            <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
              <div class="col-12">
                Nous offrons à nos clients une disponibilité sans faille et une appréhension passionnée des
                problématiques pour apporter des réponses au-delà d'un simple cadre contractuel.
              </div>
            </div>
          </div>
        </div>

        <hr>


        <div class="row section-explanation text-explanation-on-white" style="margin-top: 40px;">
          <div class="col-12">
            <p> Pour offrir un accompagnement global à nos clients, nous sommes en partenariat avec
              <a href="https://mayerprezioso.com" target="_blank">Mayer Prezioso</a>, cabinet
              d'avocats et de conseil en stratégie. Ensemble, nos équipes allient les savoir-faire d'ingénieurs, d'avocats et de consultants.
            </p>
            <p>
              <strong> Pourquoi ?</strong> Parce que notre partenariat s'est forgé autour d'hommes et de femmes experts reconnus
              dans leurs domaines, convaincus de leurs qualités respectives et de leur commune ambition de s'engager
              différemment pour leurs clients.
            </p>
          </div>
        </div>
      </div>
    </section>

    <footerComponent />


  </div>
</template>

<script>
// @ is an alias to /src
const footerComponent = () => import('@/components/footerComponent')

export default {
  name: 'Home',
  components: {
    footerComponent,
  },
  data : function () {
    return {
      activeMetierTab: 'WEB', // WEB, DATA or CYBER
    }
  },
  methods: {
    changeMetierTab (name) {
      this.activeMetierTab = name
    }
  }
}
</script>

<style>
.home {
  height: 100%;
}
#home-banner {
  background-size: cover;
  background-position:center;
  height: 100%;
  padding-top: 15px;
  background-color: black;
}



.banner-title {
  font-family: Magneto, sans-serif  !important;
  color: white;
  font-size: 60px;
  margin-top: 50px;
}

.slogan-margin {
  margin-top: 30px;
}

.banner-slogan {
  color: white;
  font-size: 20px;
  letter-spacing: 0.1em;
}

.scroll-down {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.scroll-down-icon {
  display: block;
  width: 50px;
  height: 50px;
  left: 50%;
  color: white;
  margin-bottom: 100px;
  -webkit-animation: scroll-down-anim 2s infinite;
  -moz-animation: scroll-down-anim 2s infinite;
  -o-animation: scroll-down-anim 2s infinite;
  animation: scroll-down-anim 2s infinite;
  z-index: 2;
}

@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	50% {transform: translateY(20px);}
}

@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	50% {transform: translateY(20px);}
}

@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	50% {transform: translateY(20px);}
}

@keyframes scroll-down-anim {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	50% {transform: translateY(20px);}
}




.metiers-alt-tabs {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  border: none;
}

.metiers-alt-tabs > a {
  font-size: 14px;
  text-decoration: none;
  color: #D8D8D8;
  background: none !important;
  border: none !important;
}

.metiers-alt-tabs > a:hover {
    text-decoration: none;
    color: black;
    background: none !important;
    border: none !important;
}

.metiers-alt-tabs > a.metier-tab-active {
    text-decoration: none;
    color: black;
    background: none !important;
    border: none !important;
}


.alt-tabs-icon {
    display: block;
    margin-bottom: 12px;
    font-size: 36px;
    letter-spacing: 0;
}


.metiers-tabs-cont {
  margin-top: 30px;
  padding: 30px 0;
  color: #777;
  line-height: 1.8;
}




#realisations-section {
  margin-top: 25px;
  padding-bottom: 70px;
  background-color: black;
  color: white;
}

.realisations-alt-tabs {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
    border: none;
    margin-top: 30px;
}

.realisations-alt-tabs > a {
    text-decoration: none;
    color: #A3A3A3;
    background: none !important;
    border: none !important;
}

.realisations-alt-tabs > a > small {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    letter-spacing: 0em;
    text-transform: lowercase;
}


.realisations-alt-tabs > a:hover {
    text-decoration: none;
    color: white;
    background: none !important;
    border: none !important;
}

.realisations-alt-tabs > a.realisations-tab-active {
    text-decoration: none;
    color: white;
    background: none !important;
    border: none !important;
}

.realisations-tabs-cont {
  margin-top: 30px;
  padding: 30px 0;
  color: #777;
  line-height: 1.8;
}



.section-paragraph {
  font-size: 16px;
}

.circles{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.3);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {
  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }
  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }
}



@media (min-width: 480px) {
  #home-banner {
    height: 100%;
    width: 100%;
    position:relative;
  }
  .banner-title {
    font-size: 100px;
    margin-top: 50px;
  }
  .banner-slogan {
    font-size: 24px;
  }
  .alt-tabs-icon {
    font-size: 48px;
  }
  .metiers-alt-tabs {
    font-size: 18px;
    letter-spacing: 0.2em;
  }
  .metiers-alt-tabs > a {
    font-size: 18px !important;
    letter-spacing: 0.4em;
  }

  .realisations-alt-tabs {
    font-size: 18px;
    letter-spacing: 0.2em;
  }
}

@media (min-width: 768px) {
  .banner-title {
    font-size: 100px;
    margin-top: 100px;
  }
  .banner-slogan {
    font-size: 26px;
  }
  .card-flip {
    height: 460px;
  }
  .section-flip {
    height: 360px;
  }
  .footer-right-border {
    border-bottom: none !important;
    border-right: 1px solid hsla(0,0%,100%,.5);
  }
}

@media (min-width: 1024px) {
  .banner-title {
    font-size: 130px !important;
    margin-top: 150px;
  }
  .banner-slogan {
    font-size: 30px !important;
  }
  .card-flip {
    height: 400px;
  }
  .card-back {
    font-size: 16px;
  }
  .section-flip {
    height: 400px;
  }
  .section-paragraph {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .banner-title {
    font-size: 140px !important;
  }
  .banner-slogan {
    font-size: 32px !important;
  }
  .card-flip {
    height: 380px;
  }
  .card-back {
    font-size: 18px;
  }
  .section-flip {
    height: 350px;
  }
  .section-flip-title {
    font-size: 35px;
  }
  .section-paragraph {
    font-size: 20px;
  }
  .resultat-div {
    font-size: 30px;
    font-weight: bold;
  }
}



</style>
